import { IValidationError, Validators } from '../core/validator';
import { MultipleFieldsValidator, IFieldValidatorsPair } from '../core/multipleFieldsValidator';
import { ObjectValidator } from '../core/typeValidator';
import { SchemaValidator } from '../core/typeValidator';

export class VisualSelectorValidator extends ObjectValidator {
  public validate(input: any, path?: string, field?: string): IValidationError[] {
    if (input == null) {
      return null;
    }

    const errors = super.validate(input, path, field);
    if (errors) {
      return errors;
    }

    const fields: IFieldValidatorsPair[] = [
      {
        // Not required for this selector only - Backward compatibility 
        field: "$schema",
        validators: [Validators.stringValidator, new SchemaValidator("http://powerbi.com/product/schema#visualSelector")]
      },
      {
        field: "visualName",
        validators: [Validators.fieldRequiredValidator, Validators.stringValidator]
      }
    ];

    const multipleFieldsValidator = new MultipleFieldsValidator(fields);
    return multipleFieldsValidator.validate(input, path, field);
  }
}

export class VisualTypeSelectorValidator extends ObjectValidator {
  public validate(input: any, path?: string, field?: string): IValidationError[] {

    if (input == null) {
      return null;
    }

    const errors = super.validate(input, path, field);
    if (errors) {
      return errors;
    }

    const fields: IFieldValidatorsPair[] = [
      {
        field: "$schema",
        validators: [Validators.fieldRequiredValidator, Validators.stringValidator, new SchemaValidator("http://powerbi.com/product/schema#visualTypeSelector")]
      },
      {
        field: "visualType",
        validators: [Validators.fieldRequiredValidator, Validators.stringValidator]
      }
    ];

    const multipleFieldsValidator = new MultipleFieldsValidator(fields);
    return multipleFieldsValidator.validate(input, path, field);
  }
}

export class SlicerTargetSelectorValidator extends ObjectValidator {
  public validate(input: any, path?: string, field?: string): IValidationError[] {

    if (input == null) {
      return null;
    }

    const errors = super.validate(input, path, field);
    if (errors) {
      return errors;
    }

    const fields: IFieldValidatorsPair[] = [
      {
        field: "$schema",
        validators: [Validators.fieldRequiredValidator, Validators.stringValidator, new SchemaValidator("http://powerbi.com/product/schema#slicerTargetSelector")]
      },
      {
        field: "target",
        validators: [Validators.fieldRequiredValidator, Validators.slicerTargetValidator]
      }
    ];

    const multipleFieldsValidator = new MultipleFieldsValidator(fields);
    return multipleFieldsValidator.validate(input, path, field);
  }
}
