import { IValidationError, Validators } from '../core/validator';
import { MultipleFieldsValidator, IFieldValidatorsPair } from '../core/multipleFieldsValidator';
import { ObjectValidator } from '../core/typeValidator';

export class CustomLayoutValidator extends ObjectValidator {
  public validate(input: any, path?: string, field?: string): IValidationError[] {
    if (input == null) {
      return null;
    }
    const errors = super.validate(input, path, field);
    if (errors) {
      return errors;
    }

    const fields: IFieldValidatorsPair[] = [
      {
        field: "pageSize",
        validators: [Validators.pageSizeValidator]
      },
      {
        field: "displayOption",
        validators: [Validators.customLayoutDisplayOptionValidator]
      },
      {
        field: "pagesLayout",
        validators: [Validators.pagesLayoutValidator]
      }
    ];

    const multipleFieldsValidator = new MultipleFieldsValidator(fields);
    return multipleFieldsValidator.validate(input, path, field);
  }
}

export class VisualLayoutValidator extends ObjectValidator {
  public validate(input: any, path?: string, field?: string): IValidationError[] {
    if (input == null) {
      return null;
    }
    const errors = super.validate(input, path, field);
    if (errors) {
      return errors;
    }

    const fields: IFieldValidatorsPair[] = [
      {
        field: "x",
        validators: [Validators.numberValidator]
      },
      {
        field: "y",
        validators: [Validators.numberValidator]
      },
      {
        field: "z",
        validators: [Validators.numberValidator]
      },
      {
        field: "width",
        validators: [Validators.numberValidator]
      },
      {
        field: "height",
        validators: [Validators.numberValidator]
      },
      {
        field: "displayState",
        validators: [Validators.displayStateValidator]
      }
    ];

    const multipleFieldsValidator = new MultipleFieldsValidator(fields);
    return multipleFieldsValidator.validate(input, path, field);
  }
}

export class DisplayStateValidator extends ObjectValidator {
  public validate(input: any, path?: string, field?: string): IValidationError[] {
    if (input == null) {
      return null;
    }
    const errors = super.validate(input, path, field);
    if (errors) {
      return errors;
    }

    const fields: IFieldValidatorsPair[] = [
      {
        field: "mode",
        validators: [Validators.displayStateModeValidator]
      }
    ];

    const multipleFieldsValidator = new MultipleFieldsValidator(fields);
    return multipleFieldsValidator.validate(input, path, field);
  }
}

export class PageLayoutValidator extends ObjectValidator {
  public validate(input: any, path?: string, field?: string): IValidationError[] {
    if (input == null) {
      return null;
    }
    const errors = super.validate(input, path, field);
    if (errors) {
      return errors;
    }

    const fields: IFieldValidatorsPair[] = [
      {
        field: "visualsLayout",
        validators: [Validators.fieldRequiredValidator, Validators.pageLayoutValidator]
      },
      {
        field: "defaultLayout",
        validators: [Validators.visualLayoutValidator]
      }
    ];

    const multipleFieldsValidator = new MultipleFieldsValidator(fields);
    return multipleFieldsValidator.validate(input, path, field);
  }
}
